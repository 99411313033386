import React, { useState } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";
import Navigation from "../components/navigation/navigation";
import Seo from "../components/seo/seo";
import Contact from "../components/contact/contact";
import HeaderBcg from "../assets/images/404/404.png"

const NotFoundPage = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)

  return (
    <section className="m-404" style={{ backgroundImage: `url("${HeaderBcg}")` }}>
      <Navigation openModal={openModal} setOpenModal={setOpenModal} />
      {openModal && < Contact setOpenModal={setOpenModal} />} <Seo title="Page not found" />
      <div className="_wr" >
        <div className="m-404__content" >
          <h3>{t('notFound')}</h3>
          <Link to="/" className="a-btn -redBorder a-hover -effectThree"> Go back <span className="a-arrow -long" > </span> </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`